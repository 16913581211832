export default {
  type: "property-fees",
  amount: null,
  auction_amount: null,
  typeFee: "",
  description: "",
  date: null,
  iteration: null,
  createdBy: null,
  updatedBy: null,
  relationshipNames: ["property"],
  property: {
    type: "properties",
    id: null,
  },
};
