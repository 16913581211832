<template>
  <div>
    <form @submit.prevent="saveTemplate">
      <span class="resize-loading" v-if="!templates"></span>
      <div
        class="form-wrapper"
        v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
      >
        <base-input
          :label="`${$t('COMMON.ORGANIZATION')}`"
          :placeholder="$t('COMMON.ORGANIZATION')"
        >
          <organization-selector
            :organization="selectedOrganization ? selectedOrganization : null"
            :filterable="true"
            :showAll="false"
            @organizationChanged="
              (organizationId) => {
                selectedOrganization = organizationId;
              }
            "
          />
        </base-input>
      </div>
      <div class="row">
        <div class="col-md-12" v-for="template in templates" :key="template.id">
          <div v-if="checkStartsWith(template.block_name)" class="form-wrapper full">
            <base-input
              :label="labelNames(template.block_name)"
              :placeholder="$t('COMMON.NOTE')"
            >
              <html-editor v-model="template.content"> </html-editor>
            </base-input>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import { Alert, Card } from "element-ui";
import OrganizationSelector from "@/components/OrganizationSelector.vue";

export default {
  components: { HtmlEditor, Alert, [Card.name]: Card, [Alert.name]: Alert, OrganizationSelector },
  props: {
    name: {
      type: String,
    },
  },
  data() {
    return {
      templates: [],
      user: {},
      selectedOrganization: "",
      organizationId: "",
    };
  },
  created() {
    this.user = this.$store.getters["profile/me"];
    if (this.user.organization) {
      this.organizationId = this.user.organization.id
    } else {
      this.organizationId = this.selectedOrganization;
    }
  },
  mounted() {
    if (this.organizationId) {
      this.fetchTemplate();
    }
  },
  watch: {
    selectedOrganization(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.organizationId = newVal;
        this.fetchTemplate();
      }
    }
  },
  methods: {
    checkStartsWith(str) {
      return str.startsWith(this.name);
    },
    async fetchTemplate() {
      try {
        await this.$store.dispatch("organizations/getTemplates", this.organizationId);
        this.templates = await this.$store.getters["organizations/templates"];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
    async saveTemplate() {
      try {
        await this.$store.dispatch("organizations/saveTemplates", {
          id: this.organizationId,
          templates: this.templates,
        });
        this.$notify({
          type: "success",
          message: "Modèle modifié avec succès",
        });
        this.templates = await this.$store.getters["organizations/templates"];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
    labelNames(name) {
      if (name === "BLOCK_LETTER_INTRO") {
        return "Lettre certifiée - Bloc Introduction";
      }
      if (name === "BLOCK_LETTER_SELL_NOTICE") {
        return "Lettre certifiée - Bloc Notification de vente";
      }
      if (name === "BLOCK_LETTER_PAYMENT_TERMS") {
        return "Lettre certifiée - Bloc Conditions de paiement";
      }
      if (name === "BLOCK_LETTER_OPEN_HOURS") {
        return "Lettre certifiée - Bloc Heures d'ouverture";
      }
      if (name === "BLOCK_LETTER_IMPORTANT_NOTE") {
        return "Lettre certifiée - Bloc Note importante";
      }
      if (name === "BLOCK_LETTER_SENDER") {
        return "Lettre certifiée - Bloc nom de l'expéditeur";
      }
      if (name === "BLOCK_LETTER_SENDERS_ROLE") {
        return "Lettre certifiée - Bloc Fonction de l'expéditeur";
      }
      if (name === "BLOCK_WEBSITE_ABOUT") {
        return "Site WEB - Bloc À propos de l'organisation";
      }
      if (name === "BLOCK_WEBSITE_SALE_CONDITIONS") {
        return "Site WEB - Bloc Règlement ou condition de vente";
      }
      if (name === "BLOCK_PUBLIC_NOTICE_HEADER") {
        return "Avis Public - Bloc en-tête";
      }
      if (name === "BLOCK_PUBLIC_NOTICE_FOOTER") {
        return "Avis Public - Bloc pied de page";
      }
      if (name === "BLOCK_UNSOLD_PROPERTIES_HEADER") {
        return "Propriétés non vendues - Bloc en-tête";
      }
      if (name === "BLOCK_UNSOLD_PROPERTIES_SIGNATURE") {
        return "Propriétés non vendues - Bloc signature";
      }
      if (name === "BLOCK_UNSOLD_PROPERTIES_SENDER") {
        return "Propriétés non vendues - Bloc nom de l'expéditeur";
      }
      if (name === "BLOCK_UNSOLD_PROPERTIES_SENDER_ROLE") {
        return "Propriétés non vendues - Bloc Fonction de l'expéditeur";
      }
      if(name === "BLOCK_CERTIFICATES_AWARDS_SUBJECT"){
        return "Certificat d'adjudication - Bloc Objet";
      }
      if(name === "BLOCK_CERTIFICATES_AWARDS_MESSAGE"){
        return "Certificat d'adjudication - Bloc Message";
      }
      if(name === "BLOCK_CERTIFICATES_AWARDS_SIGNATURE"){
        return "Certificat d'adjudication - Bloc Signature";
      }
      if(name === "BLOCK_CERTIFICATES_AWARDS_DELIVERED_AT"){
        return "Certificat d'adjudication - Bloc livraison";
      }
      if(name === "BLOCK_CERTIFICATES_AWARDS_DESCRIPTION_ADDRESS"){
        return "Certificat d'adjudication - Bloc description et adresse";
      }
      if(name === "BLOCK_CERTIFICATES_AWARDS_AMOUNT_TAXES_TOTAL"){
        return "Certificat d'adjudication - Bloc montant, taxes et total";
      }
      if(name === "BLOCK_CERTIFICATES_AWARDS_OWNER_INFOS"){
        return "Certificat d'adjudication - Bloc Infos proprétaire";
      }
    },
  },
};
</script>
<style>
.quill .ql-container .ql-editor {
  border-radius: 0 0 8px 8px;
  color: #000;
}

.form-group {
  margin: 0;
}
</style>
