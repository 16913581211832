<template>
  <div class="row">
    <div class="custom-header mt-4">
      <h3>{{ $t("PROPERTIES.APPLICABLES_FEES") }}</h3>
      <div class="">
        <base-button
            @click="openApplyFeeModal()"
            type="button"
            class="elite-button add"
        >
          <span class="btn-inner--icon">
            <i class="far fa-plus-circle"></i>
          </span>
          <span class="btn-inner--text">
            {{ $t("AUCTIONS.APPLY_FEES") }}
          </span>
        </base-button>
      </div>
    </div>

    <div class="row col-12">
      <div class="page-wrapper-table">
        <div class="page-wrapper-table-body">
          <div class="page-wrapper-table-body-inner">
            <el-table
              class="align-items-center table-flush"
              header-row-class-name="thead-light"
              :data="propertyFees"
            >
              <el-table-column :label="$t('PROPERTIES.TYPE_FEE')" min-width="30">
                <template v-slot="{ row }">
                  {{ $t(`PROPERTIES.TYPE_${row.type_fee}`) }}
                </template>
              </el-table-column>

              <el-table-column :label="$t('AUCTIONS.AMOUNT')" min-width="30">
                <template v-slot="{ row }">
                  <span v-if="row.amount">{{
                    $formatCurrency(row.auction_amount)
                  }}</span>
                  <span v-else>-</span>
                </template>
              </el-table-column>

              <el-table-column :label="$t('PROPERTIES.DATE_FEE')" min-width="30">
                <template v-slot="{ row }">
                  <span v-if="row.amount">{{ $formatDate(row.date) }}</span>
                  <span v-else>-</span>
                </template>
              </el-table-column>

              <el-table-column min-width="10">
                <div slot-scope="{ row }" class="table-actions">
                  <el-tooltip :content="$t('COMMON.EDIT')" placement="top">
                    <a
                      type="text"
                      @click="openApplyFeeModal(row)"
                      class="table-action"
                      data-toggle="tooltip"
                      style="cursor: pointer"
                    >
                      <i class="fal fa-edit"></i>
                    </a>
                  </el-tooltip>
                </div>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Table, TableColumn, Tooltip, Button } from "element-ui";
import formMixin from "@/mixins/form-mixin";
import Users from "@/components/Users.vue";

export default {
  name: "auction-applied-fees-list-table",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    Users,
  },

  mixins: [formMixin],

  props: {
    filterAuctionId: {
      handler: "getListDebounced",
      immediate: true,
    },
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
  },

  data() {
    return {
      propertyFees: [],
      propertyFeeData: {},
    };
  },

  computed: {},
  created(){
    this.getList();
  },

  methods: {
    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),
    openApplyFeeModal(propertyFee) {
      this.$emit("onOpenApplyFeeModal", propertyFee);
    },
    async getList() {
      try {
        this.loading = true;
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...(this.query ? { search: this.query } : {}),
          },
        };

        if (this.filterAuctionId) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              auction: this.filterAuctionId,
            },
          };
        }
        await this.$store.dispatch("propertyFees/list", params);
        this.propertyFees = this.$store.getters["propertyFees/list"];
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
  },

  watch: {},
};
</script>

<!-- <style>
.purchasesOrder-item-excerpt p,
.purchasesOrder-item-excerpt,
.purchasesOrder-item-excerpt * {
  font-size: 0.7rem;
}
</style> -->
