<template>
  <div class="container-fluid">
    <div class="page-wrapper">
      <div slot="header" class="page-wrapper-header">
        <div class="row">
          <div class="col-6">
            <h3 class="mb-0">{{ $t("COMMON.TEMPLATES") }}</h3>
          </div>
        </div>
      </div>
      <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
        <div class="row">
          <a
            class="col-4 shortcut-item"
            href="#"
            @click.prevent="
              openModal('ORGANIZATIONS.CERTIFIED_LETTER_CONTENT', 'BLOCK_LETTER')
            "
          >
            <span class="shortcut-media avatar avatar-xl rounded-circle bg-info">
              <i class="ni ni-2x ni-books"></i>
            </span>
            <small>{{ $t("ORGANIZATIONS.CERTIFIED_LETTER_CONTENT") }}</small>
          </a>
          <a
            class="col-4 shortcut-item"
            href="#"
            @click.prevent="
              openModal('ORGANIZATIONS.PUBLIC_NOTICE_CONTENT', 'BLOCK_PUBLIC_NOTICE')
            "
          >
            <span class="shortcut-media avatar avatar-xl rounded-circle bg-info">
              <i class="ni ni-2x ni-album-2"></i>
            </span>
            <small>{{ $t("ORGANIZATIONS.PUBLIC_NOTICE_CONTENT") }}</small>
          </a>
          <a
            class="col-4 shortcut-item"
            href="#"
            @click.prevent="openModal('ORGANIZATIONS.WEBSITE_CONTENT', 'BLOCK_WEBSITE')"
          >
            <span class="shortcut-media avatar avatar-xl rounded-circle bg-info">
              <i class="ni ni-2x ni-world-2"></i>
            </span>
            <small>{{ $t("ORGANIZATIONS.WEBSITE_CONTENT") }}</small>
          </a>
          <a
            class="col-4 shortcut-item"
            href="#"
            @click.prevent="openModal('ORGANIZATIONS.UNSOLD_PROPERTIES_CONTENT', 'BLOCK_UNSOLD_PROPERTIES')"
          >
            <span class="shortcut-media avatar avatar-xl rounded-circle bg-info">
              <i class="ni ni-2x ni-archive-2"></i>
            </span>
            <small>{{ $t("ORGANIZATIONS.UNSOLD_PROPERTIES_CONTENT") }}</small>
          </a>
          <a
            class="col-4 shortcut-item"
            href="#"
            @click.prevent="
              openModal('ORGANIZATIONS.CERTIFICATES_AWARDS_CONTENT', 'BLOCK_CERTIFICATES_AWARDS')
            "
          >
            <span class="shortcut-media avatar avatar-xl rounded-circle bg-info">
              <i class="ni ni-2x ni-books"></i>
            </span>
            <small>{{ $t("ORGANIZATIONS.CERTIFICATES_AWARDS_CONTENT") }}</small>
          </a>
        </div>
      </card>
    </div>
    <div
      v-if="isModalOpened"
      class="resizable-wrapper"
      v-bind:class="[isModalOpened ? 'show' : 'hide']"
      :key="renderKey * 200"
    >
      <div class="resizable-wrapper-content">
        <div class="resizable-wrapper-content-header">
          <div class="resizable-wrapper-content-header-left">
            <h1>
              {{ $t(`${modalTitle}`) }}
            </h1>
          </div>
          <div class="resizable-wrapper-content-header-right">
            <base-button type="success" icon size="sm" @click="saveTemplate()">
              <i class="fal fa-check"></i>
              <span>{{ $t("ORGANIZATIONS.VALIDATE") }}</span>
            </base-button>
            <button class="close" @click="isModalOpened = false">
              <i class="fal fa-times"></i>
            </button>
          </div>
        </div>
        <div class="resizable-wrapper-content-body flex-column px-5 py-4">
          <div class="row">
            <div class="col-md-10">
              <el-card
              v-if="blockName === 'BLOCK_LETTER'"
                class="box-card mb-3"
                shadow="never"
                :body-style="{ color: '#E6A23C', background: '#fdf6ec' }"
              >
                <div class="font-weight-bold mb-4">IMPORTANT !</div>
                <div class="text-xs mb-3">
                  Vous pouvez utiliser des shortcodes pour afficher des données provenant
                  des encans et des propriétés sur vos lettres certifiées. La liste
                  des shortcodes est énumérée ci-dessous:
                </div>
                <div class="text-xs">
                  <div class="row">
                    <span class="font-weight-bolder col-2">[START_DATE]</span
                    ><span class="col-7">Date de début de l'encan</span>
                  </div>
                  <div class="row">
                    <span class="font-weight-bolder col-2">[START_HOUR]</span
                    ><span class="col-7">Heure de début de l'encan</span>
                  </div>
                  <div class="row">
                    <span class="font-weight-bolder col-2">[AUCTION_ADDRESS]</span
                    ><span class="col-7">Adresse où aura lieu l'encan</span>
                  </div>
                  <div class="row">
                    <span class="font-weight-bolder col-2">[AUCTION_CITY]</span
                    ><span class="col-7">Ville où aura lieu l'encan l'encan</span>
                  </div>
                  <div class="row">
                    <span class="font-weight-bolder col-2">[CASE_NUMBER]</span
                    ><span class="col-7">Numéro de dossier de la propriété</span>
                  </div>
                </div>
              </el-card>
            </div>
          </div>
          <templates-page ref="template" :name="blockName"></templates-page>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TemplatesPage from "./TemplatesPage.vue";
import { Card } from "element-ui";

export default {
  layout: "DashboardLayout",

  components: { TemplatesPage, [Card.name]: Card },

  mixins: [],

  data() {
    return {
      renderKey: 1,
      isModalOpened: false,
      modalTitle: "",
      blockName: "",
    };
  },

  created() {},

  methods: {
    openModal(title, blockName) {
      this.modalTitle = title;
      this.blockName = blockName;
      this.isModalOpened = true;
    },
    saveTemplate() {
      this.$refs.template.saveTemplate();
    },
  },
};
</script>
