import {
  STATUS_ACTIVE,
  STATUS_CONFIRMED,
  STATUS_SOLD,
  STATUS_DELETED,
  STATUS_INACTIVE,
  TAXES_MUNICIPAL,
  TAXES_SCHOOL,
  TYPE_COMMERCIAL,
} from "../../constants/properties";

import {
  TYPE_BY_PROPERTY,
  TYPE_PRORATA,
} from "../../constants/propertyFees";

export default {
  PROPERTIES_LIST: "Liste des propriétés",
  ADD_PROPERTY: "Ajouter une propriété",
  VIEW_PROPERTY: "Voir la propriété",
  PROPERTY_ADDED: "Propriété ajoutée",
  EDIT_PROPERTY: "Modifier une propriété",
  PROPERTY_UPDATED: "Propriété mise à jour",
  DELETE_THIS_PROPERTY: "Supprimer cette propriétée ?",
  ENABLE_THIS_PROPERTY: "Désirez-vous afficher la propriété au public ? ",
  DISABLE_THIS_PROPERTY:
    "Désirez-vous retirer l’affichage de la propriété au public ?",
  PROPERTY_DELETED: "Propriété supprimée",
  PROPERTY_ENABLE: "Propriété activée",
  PROPERTY_DISABLE: "Propriété désactivée",
  SOLD_AT: "Date de vente",
  NAME: "Nom de la propriété",
  SOLD_AMOUNT: "Montant de la vente",
  BID_STARTING_AMOUNT: "Mise à prix de départ",
  SOLD: "Montant",
  REGISTRATION_NUMBER: "Matricule",
  BATCH_NUMBER: "Lot(s)",
  BATCH: "Lot numéro",
  BATCH_NUMBERS: "Numéro(s) de lot",
  CADASTRE: "Cadastre",
  TYPE: "Type de propriété",
  EXPORT_EXCEL: "Exporter en excel",
  TAXES_DUE: "Taxes dûes",
  PROPERTIES_TYPE_COMMERCIAL: "Commercial",
  PROPERTIES_TYPE_INDUSTRIAL: "Industriel",
  PROPERTIES_TYPE_VACANT_LAND: "Terrain",
  PROPERTIES_ORIGIN_CSS: "CSS",
  PROPERTIES_ORIGIN_MUNICIPALITY: "MUNICIPALITÉ",
  'PROPERTIES_ORIGIN_CSS & MUNICIPALITY': "CSS & MUNICIPALITÉ",
  PROPERTIES_TYPE_RESIDENTIAL: "Résidentiel",
  PROPERTIES_TYPE_LONG_COMMERCIAL: "Commercial (taxable en tout temps)",
  PROPERTIES_TYPE_LONG_INDUSTRIAL: "Industriel (taxable en tout temps)",
  PROPERTIES_TYPE_LONG_VACANT_LAND: "Terrain(taxable en tout temps)",
  PROPERTIES_TYPE_LONG_RESIDENTIAL: "Résidentiel (non taxable)",
  PROPERTIES_TAXES_MUNICIPAL: "Municipale",
  PROPERTIES_TAXES_DUE_MUNICIPAL: "Taxes dues Municipalités",
  PROPERTIES_TAXES_DUE_SCHOOL: "Taxes dues commissions scolaires",
  PROPERTIES_TAXES_SCHOOL: "Scolaire",
  PROPERTIES_STATUS_CANCEL: "Annulé",
  PROPERTIES_STATUS_PENDING: "En traitement",
  PROPERTIES_STATUS_ACTIVE: "Actif",
  PROPERTIES_STATUS_CONFIRMED: "Confirmé",
  PROPERTIES_STATUS_SOLD: "Vendu",
  PROPERTIES_SOLD: "Enchères remportées",
  PROPERTIES_UNSOLD: "Enchères non remportées",
  EXPORT_CONFIRMED: "Liste maître (confirmés)",
  EXPORT_ALL: "Exporter",
  PROPERTIES_STATUS_APPROVED: "Approuvé",
  SOLD_PROPERTIES: "Propriétés vendues",
  UNSOLD_PROPERTIES: "Propriétés non vendues",
  SUPERIOR_COURT_PROPERTIES: "Liste Immeubles cour supérieure",
  PAID_PROPERTIES: "Liste des propriétés payées avant la vente",
  MRC_FEES: "Frais de la MRC",
  PROPERTIES_ACTIVE: "Activé",
  DIRECT_MAIL: "Lettres certifiées",
  PUBLIC_NOTICES: "Avis Publics",
  PUBLIC_NOTICES_PDF: "Avis Publics PDF",
  PUBLIC_NOTICES_EXCEL: "Avis Publics Excel",
  SELECTED_DIRECT_MAIL: "Lettres certifiées (sélectionnés)",
  STATE: "Etat",
  DESIGNATION: "Désignation",
  REGISTRATION_DIVISION: "Circonscription foncière",
  ORIGIN: "Provenance",
  PROPERTIES_DISABLE: "Désactivé",
  SALE_CONFIRMED_AT: "Date confirmée",
  ACQUISITION_NUMBER: "Numéro d’acquisition",
  ACQUISITION_METHODE: "Mode d’acquisition",
  PROPERTY_NUMBER: "Numéro de dossier",
  REFERENCE_CSS: "Référence CSS",
  PROPERTY_TAX_DUE: "Total taxes dûes",
  PROPERTY_TOTAL_DUE: "Total dû",
  PROPERTY_TOTAL_PAIED: "Total payé",
  PROPERTY_TOTAL_REFUND: "Total à rembourser",
  EXPORT_FILE_NAME_OF: "Proprietes_du_",
  LETTERS_FILE_NAME_OF: "Lettres_citoyens_du_",
  THUMBNAILS_FILE_NAME_OF: "Vignettes_du_",
  PUBLIC_NOTICE_FILE_NAME_OF: "Avis_publics_du_",
  SOLD_PROPERTIES_FILE_NAME_OF: "Propriétés_vendues_du_",
  UNSOLD_PROPERTIES_FILE_NAME_OF: "Propriétés_non_vendues_du_",
  SUPERIOR_COURT_PROPERTIES_FILE_NAME_OF: "Propriétés_cour_supérieure_du_",
  PAID_PROPERTIES_FILE_NAME_OF: "Propriétés_payées_du_",
  PRINT: "Imprimer",
  PRINT_ALL: "Imprimer tout",
  CHANGE_VISIBILITY: "Changer l'affichage",
  CHANGE_READY_STATUS:
    "Vous êtes sur le point de changer le statut de cette propriété qui était prête à l’encan.",
  CONFIRMATION_QUESTION:
    "Êtes-vous certain de vouloir changer le statut à “{status}” ?",
  DISPLAY_ON_WEB_SITE: "Affiché sur site web ?",
  CANCEL_REASON: "Raison d'annulation",
  PRINT_ALL_TOOLTIP:
    "Imprimer les lettres citoyens pour toutes les propriétés dont le statut est confirmé",
  PRINT_SELECTION: "Imp. sélection",
  PRINT_SELECTION_TOOLTIP:
    "Imprimer les lettres citoyens pour les propriétés sélectionnées dont le statut est confirmé",
  TRANSACTION_DATE: "Date de transaction",
  PAYMENT_RECEIVED_BY: "Paiement reçu par",
  PAYMENT_DETAILS: "Détail du paiement",
  PRINT_RECEIPT: "Imprimer le reçu",
  PAYMENT_INFOS: "Informations suplémentaires",
  IMPORT_PREVIEW: "Prévisualisation de l'import",
  FINALIZE_IMPORT: "Finaliser l'import",
  FIELDS_CREATED: "Propriétés créées",
  STARTING_AMOUNT_AUTOMATIC: "Prix de départ automatique",
  MRC_FEES_AUTOMATIC: "Calcul des frais organisation automatique",
  CONTACTS_CREATED: "Contacts créés",
  FIELDS_UPDATED: "Propriétés mis à jour",
  CONTACTS_UPDATED: "Contacts mis à jour",
  FIELDS_ERRORS: "Erreurs",
  ERROR: "Description de l'erreur",
  DETAIL: "Détails",
  DELETE_TRANSACTION_ON_PROPERTY:
    "Voulez vous supprimer les transactions de cette propriété ?",
  PRINT_MODAL_HEADER:
    "Veuillez renseigner les informations complémentaires",
  APPLICABLES_FEES: "Frais Applicables",
  PRINT_AWARD_CERTIFICATES: "Certificats d'adjudication",
  PRINT_AWARD_CERTIFICATES_FILE_NAME_OF: "certificats_adjudication_",
  PRINT_AWARD_CERTIFICATE: "Certificat d'adjudication",
  PRINT_AWARD_CERTIFICATE_FILE_NAME_OF: "certificat_adjudication_",
  TYPE_BY_PROPERTY: 'Séparé également par propriété',
  TYPE_PRORATA: 'Frais au prorata des taxes dûs',
  TYPE_DESCRIPTION_BY_PROPERTY: 'Le montant sera divisé sur le nombre des propriété',
  TYPE_DESCRIPTION_PRORATA: 'Les frais sont répartis proportionnellement en fonction des montants dus pour chaque propriété par rapport au montant total dû pour l\'ensemble des propriétés',
  DATE_FEE: 'Date',
  TYPE_FEE: 'Type',
  IMPORT_MODEL_FILE: 'Télécharger le modèle',
  IMPORT_FILE: 'Importer',
  FILE_IMPORTED: 'Fichier importé',
  EXPORT_MUNICIPALITIES_SUMS_DUE: 'Sommes dues aux municipalités',
  MUNICIPALITIES_SUMS_DUE_FILENAME: 'sommes_dues_aux_municipalites_',
};
