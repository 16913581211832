var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{ref:"profile_form",staticClass:"add-form",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return (() => {}).apply(null, arguments)}}},[_c('div',{staticClass:"form-wrapper"},[(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ORGANIZATIONS))?_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.ORGANIZATION')} (*)`,"placeholder":_vm.$t('COMMON.ORGANIZATION')}},[_c('organization-selector',{attrs:{"organization":_vm.supplier.organization.id,"filterable":true,"showAll":false},on:{"organizationChanged":(organizationId) => {
            _vm.supplier.organization.id = organizationId;
            _vm.onFormChanged();
          }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.organization}})],1),_c('div',{staticClass:"form-wrapper"},[(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_LOCATIONS))?_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.LOCATION')}`,"placeholder":_vm.$t('COMMON.LOCATION')}},[_c('locations-selector',{attrs:{"locations":_vm.supplier.allowedLocations,"filterable":true,"showAll":false,"multiple":true,"organization":_vm.supplier.organization.id},on:{"locationsChanged":(locations) => {
            _vm.supplier.allowedLocations = locations;
            _vm.onFormChanged();
          }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.location}})],1),_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.COMPANY_NAME')} (*)`},on:{"change":() => {
          _vm.onFormChanged();
        }},model:{value:(_vm.supplier.company_name),callback:function ($$v) {_vm.$set(_vm.supplier, "company_name", $$v)},expression:"supplier.company_name"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.address}})],1),_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.FISCAL_NUMBER')} (*)`},on:{"change":() => {
          _vm.onFormChanged();
        }},model:{value:(_vm.supplier.fiscal_number),callback:function ($$v) {_vm.$set(_vm.supplier, "fiscal_number", $$v)},expression:"supplier.fiscal_number"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.fiscal_number}})],1),_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.EMAIL')} (*)`},on:{"change":() => {
          _vm.onFormChanged();
        }},model:{value:(_vm.supplier.email),callback:function ($$v) {_vm.$set(_vm.supplier, "email", $$v)},expression:"supplier.email"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.email}})],1),_c('div',{staticClass:"form-wrapper full"},[_c('div',{staticClass:"row equal"},[_c('div',{staticClass:"equal-item type"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.TYPE')}`}},[_c('el-select',{attrs:{"label":_vm.$t('COMMON.TYPE'),"placeholder":_vm.$t('COMMON.TYPE')},on:{"change":(type) => {
                _vm.supplier.phone_type = type;
                _vm.onFormChanged();
              }},model:{value:(_vm.supplier.phone_type),callback:function ($$v) {_vm.$set(_vm.supplier, "phone_type", $$v)},expression:"supplier.phone_type"}},_vm._l((_vm.phoneTypesOptions),function(value,key){return _c('el-option',{key:key,attrs:{"value":value,"label":_vm.$t(`COMMON.PHONE_TYPE_${value}`)}})}),1)],1)],1),_c('div',{staticClass:"equal-item phone"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.PHONE')}`}},[_c('phone-number-input',{attrs:{"phoneNumber":_vm.supplier.phone},on:{"phoneNumberChanged":(phone) => {
                _vm.supplier.phone = phone;
                _vm.onFormChanged();
              }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.phone}})],1),_c('div',{staticClass:"equal-item extension"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.EXTENSION')}`,"inputClasses":'extension-input'},on:{"change":() => {
              _vm.onFormChanged();
            }},model:{value:(_vm.supplier.phone_extension),callback:function ($$v) {_vm.$set(_vm.supplier, "phone_extension", $$v)},expression:"supplier.phone_extension"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.phone_extension}})],1)])]),_c('div',{staticClass:"form-wrapper full"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.OTHER_PHONES')}`}},[_c('phone-numbers-selector',{attrs:{"phoneNumbers":_vm.supplier.other_phones},on:{"phoneNumbersChanged":(phoneNumbers) => {
            _vm.supplier.other_phones = phoneNumbers;
            _vm.onFormChanged();
          }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.other_phones}})],1),_c('div',{staticClass:"form-wrapper full"},[_c('addresses-selector',{on:{"addressInputChangeChanged":(values) => {
          _vm.supplier = {
            ..._vm.supplier,
            ...values,
            long: values.longitude,
            lat: values.latitude,
          };
          _vm.onFormChanged();
        }}})],1),_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.COUNTRY')} (*)`,"placeholder":_vm.$t('COMMON.COUNTRY')}},[_c('country-selector',{attrs:{"country":_vm.supplier.country,"filterable":true,"showAll":false},on:{"countryChanged":(country) => {
            _vm.supplier.country = country;
            _vm.onFormChanged();
          }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.country}})],1),_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.STATE')} (*)`,"placeholder":_vm.$t('COMMON.STATE')}},[_c('state-selector',{attrs:{"country":_vm.supplier.country,"state":_vm.supplier.state,"filterable":true,"showAll":false},on:{"stateChanged":(state) => {
            _vm.supplier.state = state;
            _vm.onFormChanged();
          }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.state}})],1),_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.CITY')} (*)`},on:{"change":() => {
          _vm.onFormChanged();
        }},model:{value:(_vm.supplier.city),callback:function ($$v) {_vm.$set(_vm.supplier, "city", $$v)},expression:"supplier.city"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.city}})],1),_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.ZIPCODE')} (*)`},on:{"change":() => {
          _vm.onFormChanged();
        }},model:{value:(_vm.supplier.zipcode),callback:function ($$v) {_vm.$set(_vm.supplier, "zipcode", $$v)},expression:"supplier.zipcode"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.zipcode}})],1),_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.ADDRESS')} (*)`},on:{"change":() => {
          _vm.onFormChanged();
        }},model:{value:(_vm.supplier.address),callback:function ($$v) {_vm.$set(_vm.supplier, "address", $$v)},expression:"supplier.address"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.address}})],1),_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.TAGS')}`,"placeholder":`${_vm.$t('COMMON.TAGS')}`}},[_c('tags-selector',{attrs:{"tags":_vm.supplier.tags,"disabled":!_vm.supplier.organization.id,"organization":_vm.supplier.organization.id},on:{"tagsChanged":(tags) => {
            _vm.supplier.tags = tags;
            _vm.onFormChanged();
          }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.tags}})],1),_c('h2',{staticClass:"col-12 mt-2 mb-2"},[_vm._v(_vm._s(_vm.$t("COMMON.BILLING_ADDRESS")))]),_c('base-checkbox',{staticClass:"form-wrapper",on:{"input":_vm.applySupplierDataToBilling},model:{value:(_vm.billingAddressSameAsSupplier),callback:function ($$v) {_vm.billingAddressSameAsSupplier=$$v},expression:"billingAddressSameAsSupplier"}},[_c('span',{staticClass:"form-control-label"},[_vm._v(" "+_vm._s(_vm.$t("SUPPLIERS.BILLING_INFORMATIONS_SAME_AS_SUPPLIER"))+" ")])]),(!_vm.billingAddressSameAsSupplier)?_c('billing-informations-form',{attrs:{"billingInformationsData":_vm.supplier,"formErrors":_vm.formErrors,"type":_vm.supplier.supplier_type},on:{"onChangeBillingInformations":_vm.billingInformationsChanged}}):_vm._e(),_c('div',{staticClass:"submit-wrapper"},[_c('base-button',{staticClass:"btn btn-sm elite-submit",attrs:{"type":"button","native-type":"submit","disabled":_vm.loading}},[(_vm.loading)?_c('i',{staticClass:"fas fa-spinner fa-spin"}):_vm._e(),_vm._v(" "+_vm._s(_vm.supplier.id ? _vm.$t("SUPPLIERS.EDIT_SUPPLIER") : _vm.$t("SUPPLIERS.ADD_SUPPLIER"))+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }