<template>
  <modal
    v-if="showModal"
    :show="showModal"
    modal-classes="modal-secondary"
    size="lg"
  >
    <form class="add-form pb-2">
      <div class="form-wrapper">
        <base-input
          type="number"
          :label="`${$t('AUCTIONS.AMOUNT')} (*)`"
          :placeholder="$t('AUCTIONS.AMOUNT')"
          v-model="propertyFee.auction_amount"
        >
        </base-input>
        <validation-error :errors="apiValidationErrors.auction_amount" />
      </div>

      <div class="form-wrapper">
        <base-input
          :label="`${$t('PROPERTIES.TYPE_FEE')} (*)`"
          :placeholder="$t('PROPERTIES.TYPE_FEE')"
        >
          <el-select
            :clearable="true"
            class="select-primary pagination-select"
            :placeholder="$t('PROPERTIES.TYPE_FEE')"
            v-model="propertyFee.type_fee"
            @change="onFormChanged"
          >
            <el-option
              class="select-primary"
              v-for="(item, key) in TYPES"
              :key="key"
              :label="$t(`PROPERTIES.TYPE_${item}`)"
              :value="item"
            >{{ $t(`PROPERTIES.TYPE_${item}`) }}
            <el-tooltip :content="$t(`PROPERTIES.TYPE_DESCRIPTION_${item}`)" placement="top">
              <a
                type="text"
                href="#"
                class="table-action info-type-icon"
                data-toggle="tooltip"
                style="cursor: pointer"
              >
                <i class="fa fa-question"></i>
              </a>
            </el-tooltip>
            </el-option>
          </el-select>
        </base-input>
        <validation-error :errors="apiValidationErrors.type_fee" />
      </div>
      <div class="form-wrapper">
      <base-input
          :label="`${$t('PROPERTIES.DATE_FEE')}`"
          :placeholder="$t('PROPERTIES.DATE_FEE')"
          v-model="propertyFee.date"
          @change="onFormChanged"
        >
          <flat-picker
            :placeholder="$t('PROPERTIES.DATE_FEE')"
            :config="{
              allowInput: true,
              enableTime: false,
              type: 'date',
              minDate: 'today',
              locale: $flatPickrLocale(),
              disable: [
                function (date) {
                  return date.getDay() === 0;
                },
              ],
            }"
            class="form-control datepicker"
            v-model="propertyFee.date"
            @on-change="onFormChanged()"
          >
          </flat-picker>
        </base-input>
        <validation-error :errors="apiValidationErrors.date" />
        </div>
      <div class="form-wrapper full">
        <!-- Excerpt -->
        <base-input
          :label="`${$t('COMMON.EXCERPT')}`"
          :placeholder="$t('COMMON.EXCERPT')"
        >
          <html-editor v-model="propertyFee.description" @change="onFormChanged()">
          </html-editor>
        </base-input>
        <validation-error :errors="apiValidationErrors.description" />
      </div>
    </form>

    <template slot="footer">
      <button
        type="submit"
        class="btn btn-primary"
        @click="submitApplyFeeForm"
        :disabled="loading"
      >
        <span v-if="loading" class="btn-inner--icon">
          <i class="fas fa-spinner fa-spin"></i>
        </span>
        {{ $t("COMMON.SUBMIT") }}
      </button>

      <button
        type="button"
        class="btn btn-link ml-auto"
        @click="closePropertyFeeModal"
        :disabled="loading"
      >
        {{ $t("COMMON.CLOSE") }}
      </button>
    </template>
  </modal>
</template>

<script>
import { Button, Tooltip } from "element-ui";
import { cloneDeep } from "lodash";
import formMixin from "@/mixins/form-mixin";
import requestErrorMixin from "@/mixins/request-error-mixin";
import ValidationError from "@/components/ValidationError.vue";
import { mapGetters } from "vuex";
import { Option, Select } from "element-ui";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { TYPES } from "@/constants/propertyFees";

export default {
  name: "auction-apply-fee",

  components: {
    ValidationError,
    HtmlEditor,
    [Button.name]: Button,
    [Select.name]: Select,
    [Option.name]: Option,
    [Tooltip.name]: Tooltip,
    flatPicker,
  },

  mixins: [formMixin, requestErrorMixin],

  props: ["auction", "propertyFee", "showModal", "loading", "formErrors"],

  data() {
    return {
      auctionFees: [],
      lastFee: {},
      TYPES,
      showApplyFeeModal: this.showModal,
      currentItem: null,
    };
  },

  computed: {
    ...mapGetters("propertyFees", ["list"]),
  },

  methods: {
    onFormChanged() {
      this.$emit("formChanged");
    },
    closePropertyFeeModal() {
      this.$emit("onCloseApplyFeeModal", true);
    },

    submitApplyFeeForm() {
      const PropertyFeeData = cloneDeep(this.propertyFee);
      this.$emit("onSubmitApplyFeeForm", PropertyFeeData);
    },

    deleteAuctionFee(auctionFeeId) {
      this.$emit("onDeleteAuctionFee", auctionFeeId);
    },
    showDescription(type){
      this.currentItem = type;
    }
  },

  mounted() {},

  watch: {
    list: {
      handler(newList) {
        this.auctionFees = newList;
        this.resetApiValidation();
        if (newList.length > 0) {
          this.lastFee = this.auctionFees[0];
          this.$emit("updateAuctionFeeModel", newList[0]);
        }
      },
      immediate: true,
    },

    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
  .info-type-icon{
    float: right;
    font-size: 10px;
  }
</style>
