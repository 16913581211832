<template>
  <div class="elite-tabs-wrapper-content">
  <div class="page-wrapper-table">
    <div class="page-wrapper-table-body">
      <div class="page-wrapper-table-body-inner">
        <el-table
          class="align-items-center table-flush"
          header-row-class-name="thead-light"
          :data="propertyFees"
        >
          <el-table-column :label="$t('PROPERTIES.TYPE_FEE')" min-width="30">
            <template v-slot="{ row }">
              {{ $t(`PROPERTIES.TYPE_${row.type_fee}`) }}
            </template>
          </el-table-column>

          <el-table-column :label="$t('AUCTIONS.AMOUNT')" min-width="30">
            <template v-slot="{ row }">
              <span v-if="row.amount">{{
                $formatCurrency(row.amount)
              }}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>

          <el-table-column :label="$t('PROPERTIES.DATE_FEE')" min-width="30">
            <template v-slot="{ row }">
              <span v-if="row.amount">{{ $formatDate(row.date) }}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>

          <el-table-column min-width="10">
            <div slot-scope="{ row }" class="table-actions">
              <el-tooltip :content="$t('COMMON.EDIT')" placement="top">
                <a
                  type="text"
                  @click="openPropertyFeeModal(row)"
                  class="table-action"
                  data-toggle="tooltip"
                  style="cursor: pointer"
                >
                  <i class="fal fa-edit"></i>
                </a>
              </el-tooltip>
            </div>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
  <property-fee-form
      v-if="propertyFeeData"
      :property="property"
      :propertyFee="propertyFeeData"
      :showModal="showPropertyFeeModal"
      @onSubmitPropertyFeeForm="handlePropertyFeeFormSubmit"
      @onClosePropertyFeeModal="closePropertyFeeModal"
    />
</div>
  
</template>

<script>
import { Table, TableColumn, Tooltip, Button } from "element-ui";
import formMixin from "@/mixins/form-mixin";
import { ORDER_STATUS_DRAFT } from "@/constants/orders";
import PropertyFeeForm from "./PropertyFeeForm.vue";

export default {
  name: "property-applicables-fees-list-table",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    PropertyFeeForm,
  },

  mixins: [formMixin],

  props: ["property", "propertyFee", "showModal", "formErrors"],

  data() {
    return {
      propertyFees: [],
      showPropertyFeeModal: false,
      propertyFeeData: null,
      loading: false,
    };
  },

  computed: {},

  methods: {
    async get(propertyFee) {
      try {
        this.loading = true;
        await this.$store.dispatch("propertyFees/get", propertyFee.id);
        this.propertyFeeData = this.$store.getters["propertyFees/propertyFee"];
        
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
    openPropertyFeeModal(propertyFee) {
      this.showPropertyFeeModal = true;
      this.get(propertyFee);
      
    },

    closePropertyFeeModal() {
      this.showPropertyFeeModal = false;
    },

    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    async getList() {
      try {
        this.loading = true;
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...(this.query ? { search: this.query } : {}),
          },
        };

        if (this.property) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              property_id: this.property.id,
            },
          };
        }
        await this.$store.dispatch("propertyFees/list", params);
        this.propertyFees = this.$store.getters["propertyFees/list"];
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
    async handlePropertyFeeFormSubmit(propertyFeeData) {
      this.loading = true;
      try {
        await this.$store.dispatch("propertyFees/update", propertyFeeData);
        this.$emit("onPropertyFeesUpdated", propertyFeeData);

        this.$notify({
          type: "success",
          message: this.$t("COMMON.ITEM_EDITED"),
        });

        this.loading = false;
        this.showPropertyFeeModal = false;
      } catch (error) {
        console.log(error);
        this.itemsFormError = error.response.data.errors;
        this.$notify({
          type: "danger",
          message: this.itemsFormError[0].detail,
        });
        this.loading = false;
      }
    },
  },

  mounted() {
    this.$parent.$on("propertyFeesUpdated", this.getListDebounced);
  },

  beforeDestroy() {
    this.$parent.$off("propertyFeesUpdated", this.getListDebounced);
  },

  watch: {
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
  },
};
</script>

<!-- <style>
.purchasesOrder-item-excerpt p,
.purchasesOrder-item-excerpt,
.purchasesOrder-item-excerpt * {
  font-size: 0.7rem;
}
</style> -->
