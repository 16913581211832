<template>
  <div class="d-inline-flex">
    <base-button
      @click="showImportModal"
      class="notification mr-2"
      type="info"
      icon
      size="sm"
    >
      <span class="btn-inner--icon">
        <i class="fa fa-cloud-download"></i>
      </span>
    </base-button>
    <modal
      :show="showModal"
      modal-classes="modal-secondary"
    >
      <div class="content-btns-modal">
        <div class="modal-header">
        <button
            type="button"
            class="btn btn-link ml-auto"
            @click="closeImportModal"
          >
            <i class="fa fa-times"></i>
          </button>
        </div>
        <a download class="btn-link" href="/models/properties.xlsx">
          <i class="fa fa-download"></i>
          {{ $t("PROPERTIES.IMPORT_MODEL_FILE") }}
        </a>
        <base-button
          @click="$refs.fieldsFileInput.click()"
          class="elite-button add"
          type="info"
          icon
          size="sm"
        >
          <span class="btn-inner--icon">
            <i class="fa fa-cloud-download"></i>
            {{ $t("PROPERTIES.IMPORT_FILE") }}
          </span>
          <input
            style="display: none"
            type="file"
            id="property-file-input"
            ref="fieldsFileInput"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            @change="importProperties"
          />
        </base-button>
      </div>
    </modal>
    <modal
      :show="showResultsModal"
      modal-classes="modal-secondary"
      size="xl"
    >
      <div>
        
        <div class="d-flex align-items-center justify-content-between mb-4">
          <h2 class="mb-0">
            {{
              $t("PROPERTIES.IMPORT_PREVIEW")
            }}
          </h2>
          <base-button
            :disabled="isDry"
            @click="runSync"
            type="button"
            class="btn btn-sm btn-primary text-uppercase px-3"
            style="width: auto"
          >
            {{ $t("PROPERTIES.FINALIZE_IMPORT") }}
          </base-button>
        </div>
        <div v-if="showResultsModal">
          <el-table
            :data="synchronisationResult"
            style="width: 100%"
            type="expand"
            row-key="id"
          >
            <el-table-column
              prop="line"
              :label="$t('PROPERTIES.FIELDS_CREATED')"
            >
              <template v-slot="{ row }">
                {{ `${row.propertiesAdded}` }}
              </template>
            </el-table-column>
            <el-table-column
              prop="line"
              :label="$t('PROPERTIES.FIELDS_UPDATED')"
            >
              <template v-slot="{ row }">
                {{ `${row.propertiesUpdated}` }}
              </template>
            </el-table-column>
            <el-table-column
              prop="line"
              :label="$t('PROPERTIES.CONTACTS_CREATED')"
            >
              <template v-slot="{ row }">
                {{ `${row.contactsAdded}` }}
              </template>
            </el-table-column>
            <el-table-column
              prop="line"
              :label="$t('PROPERTIES.CONTACTS_UPDATED')"
            >
              <template v-slot="{ row }">
                {{ `${row.contactsUpdated}` }}
              </template>
            </el-table-column>
            <el-table-column
              prop="line"
              :label="$t('PROPERTIES.FIELDS_ERRORS')"
            >
              <template v-slot="{ row }">
                {{ `${row.errors.length}` }}
              </template>
            </el-table-column>

            <el-table-column type="expand">
              <template slot-scope="props">
                <el-table
                  :data="props.row.errors"
                  style="width: 100%"
                  type="expand"
                  row-key="id"
                >
                  <el-table-column
                    prop="id"
                    :label="$t('PROPERTIES.DETAIL')"
                  >
                    <template v-slot="{ row }">
                      {{ `${row.name}` }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="name"
                    :label="$t('PROPERTIES.ERROR')"
                  >
                    <template v-slot="{ row }">
                      <span class="text-danger">{{ row.error }}</span>
                    </template>
                  </el-table-column>
                </el-table>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            layout="prev, pager, next"
            :total="synchronisationResult.length"
          ></el-pagination>
          <button
            type="button"
            class="btn btn-link ml-auto"
            @click="closeImportModal"
          >
            {{ $t("COMMON.CLOSE") }}
          </button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import swal from "sweetalert2";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
  Pagination,
} from "element-ui";
import formMixin from "@/mixins/form-mixin";
import requestErrorMixin from "@/mixins/request-error-mixin";

export default {
  name: "import-properties-component",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    [Pagination.name]: Pagination,
  },

  mixins: [formMixin, requestErrorMixin],

  props: ["inspectionForm"],

  data() {
    return {
      showFileModal: false,
      showResultsModal: false,
      isDry: true,
      synchronisationResult: [],
      fieldsFile: null,
      file: null,
      showModal: false,
    };
  },

  created() {},

  methods: {
    showImportModal(){
      this.showModal = true;
    },
    closeImportModal(){
      this.showModal = false;
      this.showResultsModal = false;
    },
    async importProperties(e) {
      const file = e.target.files[0];
      this.file = file;
      if (!file) {
        return;
      }
      e.target.value = "";
      swal.fire({
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading();
        },
      });
      try {
        await this.$store.dispatch("files/upload", {
          image: file,
          ressource_name: "properties",
          ressource_id: "200",
          field: "files",
        });
        this.fieldsFile = await this.$store.getters["files/url"];

        const results = await this.$store.dispatch(
          "properties/importProperties",
          {
            file: this.fieldsFile,
            dry: this.isDry,
          }
        );
        this.synchronisationResult = [results];
        this.isDry = results.errors.length > 0;
        this.showModal = false;
        this.showResultsModal = true;
      } catch (error) {
        console.log(error);
        await this.showRequestError(error);
      }
      swal.close();
    },

    async runSync() {
      this.isDry = false;
      swal.fire({
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading();
        },
      });
      try {
        await this.$store.dispatch("files/upload", {
          image: this.file,
          ressource_name: "properties",
          ressource_id: "200",
          field: "files",
        });
        this.fieldsFile = await this.$store.getters["files/url"];

        const results = await this.$store.dispatch(
          "properties/importProperties",
          {
            file: this.fieldsFile,
            dry: this.isDry,
          }
        );
        this.$emit("syncSuccess");
        this.showResultsModal = false;
        this.$notify({
          type: "success",
          message: this.$t("PROPERTIES.FILE_IMPORTED"),
        });
      } catch (error) {
        console.log(error);
        await this.showRequestError(error);
      }
      swal.close();
    },
  },
};
</script>
<style lang="scss" scoped>
  .content-btns-modal{
    text-align: center !important;
  }
  .content-btns-modal a{
    margin-right: 20px;
  }
  .modal-header{
    margin-top: -43px;
    margin-right: -50px;
    margin-bottom: -30px;
  }
</style>