<template>
  <div class="elite-tabs-wrapper-content">
    <div class="custom-header">
      <h3>{{ $t("COMMON.MRC_FEES") }}</h3>
      <div>
        <base-button
            @click="reloadMrcFees()"
            type="button"
            class="elite-button btn-success"
            :disabled="loading"
        >
          <span class="btn-inner--icon mr-1">
            <i class="far fa-sync-alt"></i>
          </span>
          <span class="btn-inner--text">
            {{ $t("AUCTIONS.UPDATE_FEES") }}
          </span>
          <span v-if="loading" class="btn-inner--icon">
            <i class="fas fa-spinner fa-spin"></i>
          </span>
        </base-button>
        <base-button
            @click="openAuctionFeeModal()"
            type="button"
            class="elite-button add"
        >
          <span class="btn-inner--icon">
            <i class="far fa-plus-circle"></i>
          </span>
          <span class="btn-inner--text">
            {{ $t("AUCTIONS.ADD_FEE_LEVEL") }}
          </span>
        </base-button>
      </div>
    </div>

    <auction-fee-list-table
        :showModal="showModal"
        :filterAuctionId="auction.id"
        @onOpenAuctionFeeModal="openAuctionFeeModal"
    />

    <auction-apply-fee
        :auction="auction"
        :propertyFee="propertyFee"
        :showModal="showApplyFeeModal"
        @onSubmitApplyFeeForm="handleApplyFeeFormSubmit"
        @onCloseApplyFeeModal="closeApplyFeeModal"
    />

    <auction-applied-fees-list-table
        :filterAuctionId="auction.id"
        @onOpenApplyFeeModal="openApplyFeeModal"
    />

    <auction-other-fees
        v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_OTHER_FEES)"
        :auction="auction"
    />

    <auction-fee-form
        :auction="auction"
        :auctionFee="auctionFee"
        :showModal="showModal"
        :loading="loading"
        :formErrors="itemsFormError"
        @onCloseAuctionFeeModal="closeAuctionFeeModal"
        @updateAuctionFeeModel="updateAuctionFeeModel"
        @onSubmitAuctionFeeForm="handleAuctionFeeFormSubmit"
        @onDeleteAuctionFee="deleteAuctionFee"
    />
  </div>
</template>
<script>
import {cloneDeep} from "lodash";
import AuctionFeeListTable from "./AuctionFeeListTable.vue";
import defaultAuctionFee from "../defaultAuctionFee";
import defaultPropertyFee from "../../PropertyManagement/defaultPropertyFee";
import AuctionFeeForm from "./AuctionFeeForm.vue";
import AuctionApplyFee from "./AuctionApplyFee.vue";
import AuctionOtherFees from "./AuctionOtherFees";
import AuctionAppliedFeesListTable from "./AuctionAppliedFeesListTable.vue";
import swal from "sweetalert2";
import {
  PROPERTY_STATUS,
  PROPERTY_TAXES,
  PROPERTY_TYPES,
  PROPERTY_ORIGINS,
  STATUS_ACTIVE,
  STATUS_APPROVED,
  STATUS_CANCEL,
  STATUS_CONFIRMED,
  STATUS_PENDING,
  STATUS_SOLD,
} from "@/constants/properties";

export default {
  name: "auction-view-fees",

  components: {
    AuctionFeeForm,
    AuctionOtherFees,
    AuctionFeeListTable,
    AuctionApplyFee,
    AuctionAppliedFeesListTable,
  },

  props: ["auction"],

  mixins: [],

  data() {
    return {
      auctionFee: cloneDeep(defaultAuctionFee),
      propertyFee: cloneDeep(defaultPropertyFee),
      loading: false,
      showModal: false,
      showApplyFeeModal:false,
      itemsFormError: null,
      amountMin: 0,
      properties: [],
      update: false,
    };
  },

  computed: {},


  methods: {
    openAuctionFeeModal(auctionFee = null) {
      if (!auctionFee) {
        this.auctionFee = cloneDeep(defaultAuctionFee);
        this.auctionFee.amount_min = !isNaN(this.amountMin)
            ? this.amountMin
            : this.auctionFee.amount_min;
      } else {
        this.auctionFee = cloneDeep(auctionFee);
      }
      this.showModal = true;
    },


    async get(propertyFeeData) {
      try {
        await this.$store.dispatch("propertyFees/get", propertyFeeData.id);
        this.propertyFee = this.$store.getters["propertyFees/propertyFee"];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
    openApplyFeeModal(propertyFeeData = null) {
      if(propertyFeeData){
        this.get(propertyFeeData);
        this.update = true;
      }
      this.showApplyFeeModal = true;
    },
    closeApplyFeeModal(){
      this.showApplyFeeModal = false;
    },

    

    async handleApplyFeeFormSubmit(propertyFeeData) {
      swal.fire({
        title: this.$t("COMMON.IN_PROCESSING"),
        onBeforeOpen: () => {
          swal.showLoading();
        },
      });
      
      if(this.update){
        let params = {
          ...(this.sort ? { sort: "created_at" } : {}),
          filter: {
            iterationAuction: {iteration : propertyFeeData.iteration , auction : this.auction.id},
          },
          include: "property",
        };
        await this.$store.dispatch("propertyFees/list", params);
        let propertyFees = this.$store.getters["propertyFees/list"];
        
        try {
          let amountAuction = propertyFeeData.auction_amount;
          if(propertyFeeData.type_fee == 'BY_PROPERTY'){
            let propertiesCount = propertyFees.length;
            let amount = propertyFeeData.amount/propertiesCount;
            propertyFeeData.amount = Math.round(amount * 100) / 100;
          }
          let sumTaxs = 0;
          if(propertyFeeData.type_fee == 'PRORATA'){
            let paramsFilter = {
              ...(this.sort ? { sort: "created_at" } : {}),
              filter: {
                auctionId: this.auction.id,
                status: STATUS_CONFIRMED,
              },
              include: "organization,allowedLocations,updatedBy,createdBy",
            };
            await this.$store.dispatch("properties/list", paramsFilter);
            this.properties = this.$store.getters["properties/list"];
            sumTaxs = Math.round(this.properties.reduce((acc, array) => acc + array.total_taxes, 0) * 100) / 100;
          }
          for(let j = 0; j < propertyFees.length; j++){
            propertyFees[j].amount = propertyFeeData.amount;
            if(propertyFeeData.type_fee == 'PRORATA'){
              let taxByTotal = this.properties[j].total_taxes/sumTaxs;
              let amountPropertyFee = amountAuction*taxByTotal;
              propertyFees[j].amount = Math.round(amountPropertyFee * 100) / 100;
            }
            propertyFees[j].auction_amount = parseFloat(amountAuction);
            propertyFees[j].type_fee = propertyFeeData.type_fee;
            propertyFees[j].date = propertyFeeData.date;
            propertyFees[j].description = propertyFeeData.description;
            propertyFees[j].updated_by = propertyFeeData.updated_by;
            await this.$store.dispatch("propertyFees/update", propertyFees[j]);
          }
            
          this.$emit("onAppliedFeeUpdated", true);
          swal.close();
          
          this.showApplyFeeModal = false;
          this.$notify({
            type: "success",
            message: this.$t("AUCTIONS.APPLIED_FEE"),
          });

        } catch (error) {
          console.log(error);
          this.itemsFormError = error.response.data.errors;
          this.loading = false;
          this.$notify({
            type: "danger",
            message: this.itemsFormError[0].detail,
          });
        }
      }else{
        let params = {
          ...(this.sort ? { sort: "created_at" } : {}),
          filter: {
            auctionId: this.auction.id,
          },
          include: "organization,allowedLocations,updatedBy,createdBy",
        };
        await this.$store.dispatch("properties/list", params);
        this.properties = this.$store.getters["properties/list"];
        
        try {
          let amountAuction = propertyFeeData.auction_amount;
          if(propertyFeeData.type_fee == 'BY_PROPERTY'){
            let propertiesCount = this.properties.length;
            let amount = propertyFeeData.amount/propertiesCount;
            propertyFeeData.amount = Math.round(amount * 100) / 100;
          }
          let sumTaxs = Math.round(this.properties.reduce((acc, array) => acc + array.total_taxes, 0) * 100) / 100;
          
          for(let j = 0; j < this.properties.length; j++){
            
            if(propertyFeeData.type_fee == 'PRORATA'){
              let taxByTotal = this.properties[j].total_taxes/sumTaxs;
              let amountPropertyFee = amountAuction*taxByTotal;
              propertyFeeData.amount = Math.round(amountPropertyFee * 100) / 100;
            }
            propertyFeeData.auction_amount = parseFloat(amountAuction);
            propertyFeeData.property.id = this.properties[j].id;
            await this.$store.dispatch("propertyFees/add", propertyFeeData);
          }
          this.$emit("onAppliedFeeUpdated", true);
          swal.close();
          
          this.showApplyFeeModal = false;
          this.$notify({
            type: "success",
            message: this.$t("AUCTIONS.APPLIED_FEE"),
          });
          
        } catch (error) {
          console.log(error);
          this.itemsFormError = error.response.data.errors;
          this.loading = false;
          this.$notify({
            type: "danger",
            message: this.itemsFormError[0].detail,
          });
        }
      }
    },

    closeAuctionFeeModal() {
      this.showModal = false;
      this.loading = false;
    },

    handleAuctionFeeFormSubmit(auctionFeeData) {
      if (!auctionFeeData.id) {
        this.addAuctionFee(auctionFeeData);
      } else {
        this.editAuctionFee(auctionFeeData);
      }
    },

    async addAuctionFee(auctionFeeData) {
      this.loading = true;
      try {
        auctionFeeData.auction.id = this.auction.id;
        await this.$store.dispatch("auctionFees/add", auctionFeeData);
        this.$emit("auctionFeesUpdated", null);

        this.$notify({
          type: "success",
          message: this.$t("COMMON.ITEM_ADDED"),
        });

        this.loading = false;
        this.showModal = false;
      } catch (error) {
        this.itemsFormError = error.response.data.errors;
        this.loading = false;
        this.$notify({
          type: "danger",
          message: this.itemsFormError[0].detail,
        });
      }
    },

    async reloadMrcFees() {
      this.loading = true;
      try {
        await this.$store.dispatch("auctions/reload", this.auction);

        this.$notify({
          type: "success",
          message: this.$t("AUCTIONS.PROPERTIES_FEE_UPDATED"),
        });

        this.loading = false;
      } catch (error) {
        this.itemsFormError = error.response.data.errors;
        this.$notify({
          type: "warning",
          message: this.itemsFormError[0].detail,
        });
        this.loading = false;
      }
    },

    async editAuctionFee(auctionFeeData) {
      this.loading = true;
      try {
        await this.$store.dispatch("auctionFees/update", auctionFeeData);
        this.$emit("auctionFeesUpdated", null);

        this.$notify({
          type: "success",
          message: this.$t("COMMON.ITEM_EDITED"),
        });

        this.loading = false;
        this.showModal = false;
      } catch (error) {
        this.itemsFormError = error.response.data.errors;
        this.$notify({
          type: "danger",
          message: this.itemsFormError[0].detail,
        });
        this.loading = false;
      }
    },

    async deleteAuctionFee(id) {
      this.loading = true;
      try {
        await this.$store.dispatch("auctionFees/destroy", id);
        this.$emit("auctionFeesUpdated", null);

        this.$notify({
          type: "success",
          message: this.$t("COMMON.ITEM_DELETED"),
        });

        this.loading = false;
        this.showModal = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    updateAuctionFeeModel(lastStep) {
      if (lastStep) {
        this.amountMin = parseFloat(lastStep.amount_max) + 1;
      }
    },
  },

  mounted() {
  },

  watch: {},
};
</script>
